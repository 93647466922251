import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import { Collapse } from 'react-bootstrap';

export default class CollapsiblePanel extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    disabled: PropTypes.bool,
    header: PropTypes.node,
    footer: PropTypes.any,
    expanded: PropTypes.bool,
    children: PropTypes.node,
    onToggle: PropTypes.func,
    styleCollapse: PropTypes.object
  };

  static getDerivedStateFromProps(props, state) {
    return {
      expanded: typeof props.expanded === 'boolean' ? props.expanded : true
    };
  }

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      expanded: typeof props.expanded === 'boolean' ? props.expanded : true
    };
  }

  toggle(event) {
    let target = event.target;
    const nodeName = event.target.nodeName.toLowerCase();
    let counter = 5;

    while (--counter > 0) {
      if (nodeName !== 'input' && nodeName !== 'button' && /panel-heading|fa-chevron/.test(target.className)) {
        counter = 0;
        if (this.props.onToggle) {
          this.props.onToggle();
        } else {
          this.setState({
            expanded: !this.state.expanded
          });
        }
      }
      if (target) {
        target = target.parentNode;
      }
    }
  }

  renderArrow() {
    if (!this.state.expanded) {
      return (
        <span
          className='pull-left fa fa-chevron-right' style={{
            lineHeight: 'inherit',
            position: 'relative',
            left: '-5px'
          }}
        />
      );
    }

    return (
      <span
        className='pull-left fa fa-chevron-down' style={{
          lineHeight: 'inherit',
          position: 'relative',
          top: '-1px',
          left: '-7px'
        }}
      />
    );
  }

  getStyleCollapse() {
    return Object.assign({}, {
      padding: '6px 15px'
    }, this.props.styleCollapse);
  }

  render() {
    let className = 'panel-heading clearfix';
    if (this.props.disabled) {
      className += ' panel-heading-disabled';
    }
    if (this.state.expanded) {
      className += ' panel-heading-expanded';
    }
    return (
      <div id={this.props.id} className='panel panel-default'>
        <div className={className} style={{ position: 'relative' }} onClick={this.toggle}>
          {this.renderArrow()}
          {this.props.header}
        </div>
        <Collapse style={this.getStyleCollapse()} className='panel-body' in={this.state.expanded}>
          <div>
            {this.props.children}
          </div>
        </Collapse>
        {this.props.footer}
      </div>
    );
  }
};
