import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import { Tabs, Tab, Button } from 'react-bootstrap';
import Fieldset from '../shared/Fieldset';
import Form from '../shared/Form';
import Field from '../shared/Field';
import Help from '../shared/Help';
import HelpIcon from '../shared/HelpIcon';
import AttorneyContainer from '../attorney/AttorneyContainer';
import { selectiveToJS } from '../../utils/immutable-helpers';
import { isCategoryMatter } from '../../utils/categories';
import { isValidDate, toISODate, formatDate } from '../../utils/date';
import PatentBots from './PatentBots';
import TransmittalLetterModal from './TransmittalLetterModal';
import ConfirmModal from '../shared/ConfirmModal';
import PatentFamilyModal from './PatentFamilyModal';

const fields = [
  'firstInventor',
  'applicationNumber',
  'confirmationNumber',
  'filingDate',
  'artUnit',
  'associateEmail',
  'matterClosed',
  'priorityDate',
  'examinerName',
  'matterCategories',
  'twoWayRelatedMatters',
  'oneWayRelatedMatters',
  'notes',
  'firmName',
  'clientId',
  'entitySize',
  'alternateId',
  'lastSweepTime',
  'hideTodo'
];

const extractState = ({ record, matterList }) => {
  const state = selectiveToJS(record, fields);

  state.lastSweepTime = !state.lastSweepTime || state.lastSweepTime === '0000-00-00 00:00:00'
    ? ''
    : formatDate(state.lastSweepTime);

  return state;
};

export default class MatterForm extends Form {
  static propTypes = {
    addFirstMatterTwoWayRelation: PropTypes.func.isRequired,
    addMatterTwoWayRelation: PropTypes.func.isRequired,
    addNotification: PropTypes.func.isRequired,
    categories: PropTypes.object,
    checkDocumentsFromIds: PropTypes.func.isRequired,
    checkEspace: PropTypes.func.isRequired,
    client: PropTypes.instanceOf(Immutable.Map),
    createMatter: PropTypes.func.isRequired,
    features: PropTypes.object.isRequired,
    fetchFamily: PropTypes.func.isRequired,
    fetchMatter: PropTypes.func.isRequired,
    fetchPatentFamily: PropTypes.func.isRequired,
    fetchPublicPairStatus: PropTypes.func.isRequired,
    logAction: PropTypes.func.isRequired,
    matterList: PropTypes.instanceOf(Immutable.List),
    onChange: PropTypes.func.isRequired,
    onSelectTab: PropTypes.func.isRequired,
    partnerEmail: PropTypes.string,
    record: PropTypes.instanceOf(Immutable.Map),
    selectedTab: PropTypes.string.isRequired,
    selectMatter: PropTypes.func.isRequired,
    sweepMatter: PropTypes.func.isRequired,
    updateMatter: PropTypes.func.isRequired,
    updatePreviouslyPaidAmount: PropTypes.func.isRequired
  };

  static defaultProps = {
    matterList: Immutable.List()
  };

  static getDerivedStateFromProps(props, state) {
    if (!Immutable.is(props.record, state.record)) {
      return {
        isSweeping: false,
        record: props.record,
        ...extractState(props)
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      transmittalLetterModal: false,
      patentFamilyModal: false
    };
  }

  onClickPatentFamily() {
    this.setState({
      patentFamilyModal: true
    });
  }

  onClickCheckEspace() {
    this.props.checkEspace(this.props.record);
  }

  onClickDocumentsFromIds() {
    this.props.checkDocumentsFromIds(this.props.record);
  }

  onClickUpdatePreviouslyPaidAmount() {
    this.props.updatePreviouslyPaidAmount(this.props.record);
  }

  onCancelPatentFamily() {
    this.setState({
      patentFamilyModal: false
    });
  }

  onConfirmPatentFamily() {
    this.setState({
      patentFamilyModal: false
    });
  }

  onClickTransmittalLetter() {
    this.setState({
      transmittalLetterModal: true
    });
  }

  onCancelTransmittalLetter() {
    this.setState({
      transmittalLetterModal: false
    });
  }

  onConfirmTransmittalLetter(values) {
    this.props.onChange(values);
    this.setState({
      transmittalLetterModal: false
    });
  }

  onClickSweep() {
    this.setState({ isSweeping: true });
    const afterSweep = () => this.setState({ isSweeping: false });
    this.props.sweepMatter(this.props.record).then(afterSweep).catch(afterSweep);
  }

  onSelectMatter(matterNumber) {
    if (matterNumber) {
      const matter = this.props.matterList.find(matter => matter.get('matterNumber') === matterNumber);
      this.props.selectMatter(matter.get('id'));
    }
  }

  onChangeEmail(ev, value) {
    this.onChange({
      target: {
        name: 'associateEmail',
        value
      }
    });
  }

  renderClosed() {
    const label = (
      <span>
        Stop IDSs
        <HelpIcon
          placement='top'
          style={{ position: 'relative', left: '10px', top: '-2px' }}
          id='help-closed'
          help='No IDSs will be generated for this matter, but it will contribute its current and future art to synchronized matters. This box should be checked for issued, abandoned, and most foreign matters.'
        />
      </span>
    );

    return (
      <div style={{ paddingTop: '12px', paddingLeft: '5px', width: '25%', float: 'left', whiteSpace: 'nowrap' }}>
        {this.renderCheckbox(label, 'matterClosed', ['OFF', 'ON'])}
        <Help text='(e.g., issued, foreign)' />
      </div>
    );
  }

  renderFiler() {
    return (
      <AttorneyContainer
        title='Attorney'
        context='matter'
        email={this.state.associateEmail || this.props.partnerEmail || ''}
        preventClear={!this.state.associateEmail}
        firmName={this.state.firmName}
        parentRecord={this.props.record}
        onChange={this.onChangeEmail}
      />
    );
  }

  onApplicationNumberChange(ev) {
    ev.target.value = ev.target.value.replace(/\s/g, '');
    this.onChange(ev);
  }

  onBlurApplicationNumber(e) {
    if (
      this.props.record.get('applicationNumber') &&
      this.props.record.get('applicationNumber') !== this.state.applicationNumber
    ) {
      this.setState({
        confirmApplicationNumber: true
      });
    } else {
      this.onBlur(e);
    }
  }

  onCancelApplicationNumber() {
    this.setState({
      confirmApplicationNumber: null,
      applicationNumber: this.props.record.get('applicationNumber')
    });
  }

  onConfirmApplicationNumber() {
    this.setState({
      confirmApplicationNumber: null
    });
    this.raiseChange('applicationNumber');
  }

  onKeyDownApplicationNumber(e) {
    if (e.which === 13) {
      setTimeout(this.onBlurApplicationNumber, 0, e);
    }
  }

  renderConfirmApplicationNumberChange() {
    const message = this.state.applicationNumber
      ? `Are you sure you want to change the application number from ${this.props.record.get('applicationNumber')} to ${this.state.applicationNumber}?`
      : 'Are you sure you want to remove the application number?';

    return this.state.confirmApplicationNumber && (
      <ConfirmModal
        focusCancel
        message={message}
        onCancel={this.onCancelApplicationNumber}
        onConfirm={this.onConfirmApplicationNumber}
      />
    );
  }

  renderApplicationNumber() {
    return (
      <Field label='Application #' style={{ width: '37.5%', float: 'left' }}>
        <input
          type='text'
          name='applicationNumber'
          placeholder='17/123,456 or CN202301234'
          value={this.state.applicationNumber || ''}
          onChange={this.onApplicationNumberChange}
          onKeyDown={this.onKeyDownApplicationNumber}
          onBlur={this.onBlurApplicationNumber}
          onFocus={this.onFocus}
          spellCheck={false}
          className='form-control'
        />
      </Field>
    );
  }

  renderRequired() {
    return (
      <Fieldset>
        {this.renderApplicationNumber()}
        {this.renderInput('Confirmation #', 'confirmationNumber', '22%')}
        {this.renderInput('Art Unit', 'artUnit', '15.5%')}
        {this.renderDateInput('Filing Date', 'filingDate', '25%')}
        {this.renderInput('First Named Inventor', 'firstInventor', '37.5%')}
        {this.renderInput('Examiner Name', 'examinerName', '37.5%')}
        {this.renderClosed()}
        <div style={{ clear: 'both' }} />
      </Fieldset>
    );
  }

  renderFieldButton(label, props) {
    return (
      <Field label='' style={{ width: 'auto' }}>
        <Button
          variant={props.variant || 'secondary'}
          size='sm'
          style={{ height: '33.5px', position: 'relative', top: '3px' }}
          onClick={props.onClick}
          disabled={props.disabled || false}
        >
          {label}
          {props.help && (
            <HelpIcon
              style={{ marginLeft: '7px' }}
              placement='top'
              help={props.help}
            />
          )}
        </Button>
      </Field>
    );
  }

  renderTransmittalLetterButton() {
    return this.renderFieldButton('Transmittal Letter', {
      disabled: this.props.features.limitedDocs,
      onClick: this.onClickTransmittalLetter
    });
  }

  renderCheckEspaceButton() {
    return this.renderFieldButton('Check Espace', {
      disabled: this.props.features.limitedDocs,
      onClick: this.onClickCheckEspace,
      help: 'Migrates this Matter\'s cited art according to Espacenet'
    });
  }

  renderPatentFamilyButton() {
    return this.renderFieldButton('Patent Family', {
      disabled: this.props.features.limitedDocs || !this.props.record.get('applicationNumber'),
      onClick: this.onClickPatentFamily,
      help: 'Adds family members to two-way sync box according to Espacenet.'
    });
  }

  renderDocumentsFromIdsButton() {
    return this.renderFieldButton('Documents from IDS', {
      disabled: !this.props.record.get('applicationNumber'),
      onClick: this.onClickDocumentsFromIds,
      help: 'Download IDS XMLs from Patent Center and extract cited references.'
    });
  }

  renderUpdatePreviouslyPaidAmountButton() {
    return this.renderFieldButton('Previously Paid Amount', {
      disabled: !this.props.record.get('applicationNumber'),
      onClick: this.onClickUpdatePreviouslyPaidAmount,
      help: 'Update previously paid amount from Patent Center data.'
    });
  }

  renderHideTodo() {
    return (
      <Field label='' style={{ width: 'auto', float: 'right' }}>
        {this.renderCheckbox('Hide Matter from To-Do List', 'hideTodo', [false, true])}
      </Field>
    );
  }

  renderOptional() {
    return (
      <Fieldset>
        {this.renderInput('Alternate ID', 'alternateId', '50%')}
        {this.renderDateInput('Priority Date', 'priorityDate', '25%')}
        {this.renderEntitySize()}
        {this.renderTransmittalLetterButton()}
        {this.renderHideTodo()}
      </Fieldset>
    );
  }

  getChangedValue(field) {
    if (field === 'lastSweepTime' && this.state[field]) {
      return toISODate(this.state[field]);
    }
    return this.state[field];
  }

  onBlurSweep(ev) {
    if (!this.state.lastSweepTime) {
      this.onBlur(ev);
    }
    if (!isValidDate(this.state.lastSweepTime)) {
      return;
    }
    this.onBlur(ev);
  }

  renderSweepDate() {
    const value = this.state.lastSweepTime || '';

    return (
      <Field
        label='Last Sweep'
        style={{ float: 'left', width: '35%' }}
        className={value && !isValidDate(value) ? 'has-feedback has-error' : ''}
      >
        <input
          type='text'
          name='lastSweepTime'
          value={value}
          disabled={this.state.isSweeping}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          onBlur={this.onBlurSweep}
          onFocus={this.onFocus}
          spellCheck={false}
          className='form-control'
        />
      </Field>
    );
  }

  isSweepDisabled() {
    if (this.state.isSweeping) {
      return true;
    }

    return (
      !this.props.client.get('sweepCitationsFlag') &&
      !this.props.client.get('sweepUsOfficeActionsFlag')
    );
  }

  renderSweep() {
    return this.renderFieldButton('Sweep', {
      disabled: this.isSweepDisabled(),
      onClick: this.onClickSweep,
      variant: 'danger'
    });
  }

  renderEntitySize() {
    return (
      <Field label='Entity Size' style={{ width: '25%' }}>
        <select
          name='entitySize'
          value={this.state.entitySize}
          onChange={this.onChange}
          className='form-control'
        >
          <option value='Large'>Undiscounted</option>
          <option value='Small'>Small</option>
          <option value='Micro'>Micro</option>
        </select>
      </Field>
    );
  }

  renderNotes() {
    return (
      <div style={{ paddingTop: '9px' }}>
        <textarea
          rows={5}
          name='notes'
          value={this.state.notes || ''}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          spellCheck={false}
          className='form-control'
        />
      </div>
    );
  }

  getNotesTitle() {
    return (
      <div>
        <span className={/\w/.test(this.props.record.get('notes')) ? 'fa fa-file' : 'fa fa-file-o'} />
        &nbsp;
        Notes
      </div>
    );
  }

  onSelectTab() {

  }

  renderPatentBots() {
    const param = (this.props.record.get('applicationNumber') || '').replace(/[,\/]/g, '');

    return (
      <PatentBots
        section='Matter Section'
        logAction={this.props.logAction}
        clientNumber={this.props.record.get('clientNumber')}
        matterNumber={this.props.record.get('matterNumber')}
        type='app-no'
        param={param}
      />
    );
  }

  renderTools() {
    return (
      <>
        <Fieldset>
          {this.props.features.sweep && this.renderSweepDate()}
          {this.props.features.sweep && this.renderSweep()}
        </Fieldset>
        <Fieldset>
          {this.renderCheckEspaceButton()}
          {this.renderPatentFamilyButton()}
          {this.props.features.admin && this.renderDocumentsFromIdsButton()}
          {this.props.features.admin && this.renderUpdatePreviouslyPaidAmountButton()}
        </Fieldset>
      </>
    );
  }

  renderTabs() {
    const showAllTabs = !isCategoryMatter(this.props.record.get('matterNumber'));

    return (
      <Tabs
        onSelect={this.onSelectTab}
        activeKey={showAllTabs ? undefined : 'notes'}
        defaultActiveKey={showAllTabs ? 'required' : 'notes'}
        id='client-tabs'
      >
        {showAllTabs && (
          <Tab eventKey='required' title='App Data'>
            {this.renderRequired()}
          </Tab>
        )}
        {showAllTabs && (
          <Tab eventKey='optional' title='Optional'>
            {this.renderOptional()}
          </Tab>
        )}
        {showAllTabs && (
          <Tab eventKey='filer' title='Signator'>
            {this.renderFiler()}
          </Tab>
        )}
        <Tab eventKey='notes' title={this.getNotesTitle()}>
          {this.renderNotes()}
        </Tab>
        {showAllTabs && <Tab title={this.renderPatentBots()} />}
        {showAllTabs && (
          <Tab eventKey='tools' title='Tools'>
            {this.renderTools()}
          </Tab>
        )}
      </Tabs>
    );
  }

  renderTransmittalLetterModal() {
    return this.state.transmittalLetterModal && (
      <TransmittalLetterModal
        matter={this.props.record}
        features={this.props.features}
        onCancel={this.onCancelTransmittalLetter}
        onConfirm={this.onConfirmTransmittalLetter}
      />
    );
  }

  renderPatentFamilyModal() {
    return this.state.patentFamilyModal && (
      <PatentFamilyModal
        matter={this.props.record}
        onCancel={this.onCancelPatentFamily}
        onConfirm={this.onConfirmPatentFamily}
        fetchMatter={this.props.fetchMatter}
        fetchPublicPairStatus={this.props.fetchPublicPairStatus}
        fetchPatentFamily={this.props.fetchPatentFamily}
        addFirstMatterTwoWayRelation={this.props.addFirstMatterTwoWayRelation}
        addMatterTwoWayRelation={this.props.addMatterTwoWayRelation}
        createMatter={this.props.createMatter}
        updateMatter={this.props.updateMatter}
        matterList={this.props.matterList}
        fetchFamily={this.props.fetchFamily}
        checkEspace={this.props.checkEspace}
      />
    );
  }

  render() {
    return (
      <div style={{ height: '200px' }}>
        {this.renderTabs()}
        {this.renderTransmittalLetterModal()}
        {this.renderPatentFamilyModal()}
        {this.renderConfirmApplicationNumberChange()}
      </div>
    );
  }
}
