import { toISODate, formatDate } from './date';

export function getPairStatusData(data) {
  if (!data.pairStatus && data.patentCenterPairStatus) {
    return {
      status: data.patentCenterPairStatus,
      date: data.patentCenterPairStatusDate,
      lookupDate: formatDate(data.patentCenterPairStatusLookupDate),
      private: true
    };
  } else if (data.pairStatus && !data.patentCenterPairStatus) {
    return {
      status: data.pairStatus,
      date: data.pairStatusDate,
      lookupDate: data.pairStatusLookupDate,
      private: false
    };
  } else if (data.pairStatus && data.patentCenterPairStatus) {
    const publicDate = toISODate(data.pairStatusDate);
    const privateDate = data.patentCenterPairStatusDate;

    if (publicDate > privateDate) {
      return {
        status: data.pairStatus,
        date: data.pairStatusDate,
        lookupDate: data.pairStatusLookupDate,
        private: false
      };
    }

    return {
      status: data.patentCenterPairStatus,
      date: data.patentCenterPairStatusDate,
      lookupDate: formatDate(data.patentCenterPairStatusLookupDate),
      private: true
    };
  }

  return {
    status: null,
    date: null,
    lookupDate: null,
    private: false
  };
}
