import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import Form from '../shared/Form';
import ConfirmModal from '../shared/ConfirmModal';
import TodoRecord from '../../models/TodoRecord';
import Field from '../shared/Field';
import attorneyList from '../../utils/attorney-list.json';

class TodoUploadForm extends Form {
  static propTypes = {
    record: PropTypes.instanceOf(Immutable.Map)
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = props.record.toJS();
  }

  renderNewApplication() {
    return this.state['application.existing'] === false && (
      <div>
        {this.renderApplicationType()}
        {this.renderUtilityType()}
        {this.renderUSDetails()}
        {this.renderDetails()}
      </div>
    );
  }

  renderDetails() {
    const showDetails = this.state['application.type'] === 'design' ||
      this.state['application.utilityType'] === 'nonprovisional' || (
      this.state['application.utilityType'] === 'international' &&
        this.state['application.internationalNumber'] &&
        this.state['application.internationalDate']
    );
    return showDetails && (
      <div className='clearfix'>
        <hr />
        <h4>Application Details</h4>
        {this.renderInput('Title', 'details.title', '100%', true)}
        <h5>First Named Inventor</h5>
        {this.renderInput('First Name', 'details.firstName', '35%', true)}
        {this.renderInput('Middle Name', 'details.middleName', '30%', true)}
        {this.renderInput('Last Name', 'details.lastName', '35%', true)}
        {this.renderInput('Docket Number', 'details.docketNumber', '50%', true)}
        {this.renderInput('Customer Number', 'details.customerNumber', '50%', true)}
      </div>
    );
  }

  renderUSDetails() {
    return this.state['application.utilityType'] === 'international' && (
      <div className='clearfix'>
        <hr />
        <h4>International Application</h4>
        {this.renderInput('International Application Number', 'application.internationalNumber', '50%', true)}
        {this.renderDateInput('International Filling Date', 'application.internationalDate', '50%', true)}
      </div>
    );
  }

  renderUtilityType() {
    return this.state['application.type'] === 'utility' && (
      <div className='clearfix'>
        {this.renderRadio('Non-Provisional', 'application.utilityType', 'nonprovisional', '40%')}
        {this.renderRadio('U.S. National Stage 35 USC 371', 'application.utilityType', 'international', '60%')}
      </div>
    );
  }

  renderApplicationType() {
    return !this.state['application.existing'] && (
      <div className='clearfix'>
        <h4>Choose Type</h4>
        {this.renderRadio('Utility', 'application.type', 'utility', '40%')}
        {this.renderRadio('Design', 'application.type', 'design', '60%')}
      </div>
    );
  }

  renderAttorney() {
    const value = this.state['application.attorneyName'];

    return (
      <Field
        label='Attorney Name'
        style={{ width: '40%' }}
        className={!value ? 'has-error has-feedback' : ''}
      >
        <select
          name='application.attorneyName'
          value={value}
          onChange={this.onChange}
          className='form-control'
          style={{ fontSize: '18px', padding: '2px 10px' }}
        >
          <option value=''>
            - Select Attorney -
          </option>
          {attorneyList.map((name, index) => (
            <option key={index} value={name}>
              {name}
            </option>
          ))}
        </select>
      </Field>
    );
  }

  renderExistingApplication() {
    return this.state['application.existing'] === true && (
      <div className='clearfix'>
        <h4>Existing Application</h4>
        {this.renderInput('Application Number', 'application.applicationNumber', '30%', true)}
        {this.renderInput('Confirmation Number', 'application.confirmationNumber', '30%', true)}
        {this.renderAttorney()}
      </div>
    );
  }

  renderIdentity() {
    const holder = this.state['identity.holder'];
    return (
      <div className='clearfix'>
        <h4>Identity</h4>
        {this.renderRadio('Certificate holder', 'identity.holder', true, '40%')}
        {this.renderRadio('Working under authority of certificate holder', 'identity.holder', false, '60%')}
        {!holder && this.renderInput('First Name', 'identity.firstName', '25%', true)}
        {!holder && this.renderInput('Last Name', 'identity.lastName', '25%', true)}
        {!holder && this.renderInput('Email', 'identity.email', '50%', true)}
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderIdentity()}
        <hr />
        <h4>Application</h4>
        {this.renderRadio('New Application', 'application.existing', false, '40%')}
        {this.renderRadio('Existing Application', 'application.existing', true, '60%')}
        <hr />
        {this.renderExistingApplication()}
        {this.renderNewApplication()}
        <hr />
        {this.renderCheckbox('Do not upload Transmittal Letter', 'application.ignoreTransmittalLetter', [false, true], '100%')}
        {this.renderCheckbox('Do not upload IDS Form', 'application.ignoreIDSForm', [false, true], '100%')}
        {this.renderCheckbox('Do not upload IDS Size Fee Written Assertion', 'application.ignoreSizeFee', [false, true], '100%')}
      </div>
    );
  }
}

export default class TodoUpload extends Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    loginEmail: PropTypes.string.isRequired,
    todo: PropTypes.instanceOf(TodoRecord).isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.todoRecord = this.props.todo.getUploadRecord()
      .set('identity.email', this.props.loginEmail);

    this.state = this.todoRecord.toJS();
  }

  onChange(values) {
    this.setState(values);
  }

  assignRefForm(element) {
    this.formRef = element;
  }

  onConfirm() {
    this.formRef.submit();
    this.props.onConfirm();
  }

  renderForm() {
    return (
      <form ref={this.assignRefForm} method='get' action='syncids://syncids.com' target='_blank'>
        <input name='url' type='hidden' value={this.props.url} />
        {Object.keys(this.state).map(key => (
          <input
            key={key}
            name={key}
            type='hidden'
            value={this.state[key]}
          />
        ))}
      </form>
    );
  }

  renderBody() {
    return (
      <div>
        {this.renderForm()}
        <TodoUploadForm
          forceChange
          onChange={this.onChange}
          record={this.todoRecord}
        />
      </div>
    );
  }

  isValid() {
    const identity = Boolean(this.state['identity.holder'] || (
      this.state['identity.firstName'] &&
      this.state['identity.lastName'] &&
      this.state['identity.email']
    ));

    const application = Boolean(!this.state['application.existing'] || (
      this.state['application.applicationNumber'] &&
      this.state['application.confirmationNumber'] &&
      this.state['application.attorneyName']
    ));

    const details = Boolean(this.state['application.existing'] || (
      this.state['details.title'] &&
      this.state['details.firstName'] &&
      this.state['details.middleName'] &&
      this.state['details.lastName'] &&
      this.state['details.docketNumber'] &&
      this.state['details.customerNumber']
    ));

    return (identity && application && details);
  }

  render() {
    return (
      <ConfirmModal
        size='lg'
        title='Matter Upload'
        confirmLabel='Upload'
        message={this.renderBody()}
        onConfirm={this.onConfirm}
        onCancel={this.props.onCancel}
        confirmDisabled={!this.isValid()}
      />
    );
  }
}
