import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import CollapsiblePanel from '../shared/CollapsiblePanel';
import ConfirmModal from '../shared/ConfirmModal';
import AlertModal from '../shared/AlertModal';
import _toLower from 'lodash/toLower';
import DocumentsList from './DocumentsList';
import DeduplicateModal from './DeduplicateModal';
import DocumentBatchModal from './DocumentBatchModal';
import DeleteDocumentModal from './DeleteDocumentModal';
import BatchUploadModal from './BatchUploadModal';
import getUSDocs from 'utils/us-docs';
import { isCategoryMatter } from '../../utils/categories';
import ConfirmFileModal from '../dashboard/ConfirmFileModal';
import DocumentListedModal from './DocumentListedModal';
import sortBy from 'lodash/sortBy';
import { toISODate } from '../../utils/date';
import BatchSweepModal from './BatchSweepModal';
import AdminDropdown from './AdminDropdown';
import DeduplicateNPLModal from './DeduplicateNPLModal';
import DocumentHistoryModal from './DocumentHistoryModal';
import BatchPDFScraperModal from './BatchPDFScraperModal';
import DocumentsFilter from './DocumentsFilter';
import DocumentsTools from './DocumentsTools';

export default class Documents extends Component {
  static propTypes = {
    addBatchDocuments: PropTypes.func.isRequired,
    addDocumentToMatter: PropTypes.func.isRequired,
    appendTranslationsToDocument: PropTypes.func.isRequired,
    batchUploadData: PropTypes.object,
    batchUploadDocument: PropTypes.func.isRequired,
    categories: PropTypes.object,
    citedFilter: PropTypes.string.isRequired,
    cleanUpTwoWayRelations: PropTypes.func.isRequired,
    clearNearDuplicate: PropTypes.func.isRequired,
    clearNplDocuments: PropTypes.func.isRequired,
    clearPendingOfficeActions: PropTypes.func.isRequired,
    client: PropTypes.instanceOf(Immutable.Map).isRequired,
    closeBatchUpload: PropTypes.func.isRequired,
    confirmBatchUpload: PropTypes.func.isRequired,
    deleteDocumentFromClient: PropTypes.func.isRequired,
    deleteDocumentFromFamily: PropTypes.func.isRequired,
    deleteDocumentFromMatter: PropTypes.func.isRequired,
    documents: PropTypes.instanceOf(Immutable.Map).isRequired,
    features: PropTypes.object.isRequired,
    fetchDocumentHistory: PropTypes.func.isRequired,
    fetchDocumentsIfNeeded: PropTypes.func.isRequired,
    fetchDocuments: PropTypes.func.isRequired,
    fetchFamily: PropTypes.func.isRequired,
    fetchNearDuplicate: PropTypes.func.isRequired,
    fetchPendingOfficeActions: PropTypes.func.isRequired,
    fileMatter: PropTypes.func.isRequired,
    getEquivalents: PropTypes.func.isRequired,
    getFirmClientSettings: PropTypes.func.isRequired,
    loadMatterNotes: PropTypes.func.isRequired,
    logAction: PropTypes.func.isRequired,
    lookUpDocuments: PropTypes.func.isRequired,
    markOfficeActionsComplete: PropTypes.func.isRequired,
    matter: PropTypes.instanceOf(Immutable.Map),
    matterNotes: PropTypes.array.isRequired,
    navigateApp: PropTypes.func.isRequired,
    nearDuplicate: PropTypes.object.isRequired,
    newDocuments: PropTypes.instanceOf(Immutable.Map).isRequired,
    notesSearch: PropTypes.string.isRequired,
    notesTab: PropTypes.string.isRequired,
    nplSuggestions: PropTypes.instanceOf(Immutable.Map).isRequired,
    pendingOfficeActions: PropTypes.array,
    refreshDocuments: PropTypes.func.isRequired,
    removeEquivalents: PropTypes.func.isRequired,
    removeNonPriorArt: PropTypes.func.isRequired,
    replaceDocNumber: PropTypes.func.isRequired,
    resolveForeign: PropTypes.func.isRequired,
    searchNotes: PropTypes.func.isRequired,
    searchNplDocuments: PropTypes.func.isRequired,
    selectCitedFilter: PropTypes.func.isRequired,
    selectNotesTab: PropTypes.func.isRequired,
    setFileReminder: PropTypes.func.isRequired,
    sortDocuments: PropTypes.func.isRequired,
    updateDocument: PropTypes.func.isRequired,
    updateDocumentCategories: PropTypes.func.isRequired,
    updateDocumentCitedState: PropTypes.func.isRequired,
    updateMatter: PropTypes.func.isRequired,
    updateMatterNotes: PropTypes.func.isRequired,
    uploadFile: PropTypes.func.isRequired,
    uploadPDFScraper: PropTypes.func.isRequired,
    viewCategory: PropTypes.func.isRequired,
    visibleDocuments: PropTypes.instanceOf(Immutable.List).isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      filter: 'all',
      showNearDuplicate: false,
      showFileModal: false,
      deduplicateNPL: null,
      showListed: false,
      fetchingPendingOfficeActions: false,
      showPriorityDate: false,
      showNPLNumber: false
    };
  }

  componentDidUpdate() {
    if (this.props.matter.get('id') !== this.loadingMatter) {
      this.loadingMatter = this.props.matter.get('id');
      this.props.fetchDocumentsIfNeeded(this.props.matter).then(() => {
        if (this.props.matter === this.loadingMatter) {
          this.loadingMatter = null;
        }
      });
    }

    this.fetchPendingOfficeActions();
  }

  fetchPendingOfficeActions() {
    if (
      !this.props.pendingOfficeActions &&
      this.props.matter.get('id') &&
      this.props.client.get('sweepCitationsFlag') &&
      !this.state.fetchingPendingOfficeActions
    ) {
      this.setState({ fetchingPendingOfficeActions: true });
      this.props.fetchPendingOfficeActions(this.props.matter.get('id')).then(() => {
        this.setState({ fetchingPendingOfficeActions: false });
      });
    }
  }

  isCategoryMatter() {
    return isCategoryMatter(this.props.matter.get('matterNumber'));
  }

  onDocChange(doc, field, newValue) {
    const matterId = this.props.matter.get('id');
    const documentNumber = doc.getRealDocumentNumber();
    const documentId = doc.get('id');
    const type = doc.get('type');

    const hasNewValue = typeof newValue !== 'undefined' && newValue !== '';

    // existing document
    if (documentNumber) {
      switch (field) {
        case 'matterNotes':
          this.props.updateMatterNotes(matterId, documentNumber, newValue);
          break;
        case 'categories':
          this.props.updateDocumentCategories(doc, newValue);
          break;
        case 'nonPatentBib':
          if (hasNewValue) {
            this.props.updateDocument({
              id: doc.get('id'),
              nonPatentBib: newValue
            }, documentNumber).then((r) => {
              if (r && r.status === 'duplicate') {
                this.setState({
                  deduplicateNPL: { doc, newValue },
                  updateDoc: doc
                });
              }
            });
          } else {
            this.setState({
              updateDoc: undefined,
              confirmDeleteDocument: doc
            });
          }
          break;
        case 'documentNumber': {
          const newValueLower = _toLower(newValue);
          if (newValueLower === 'deleteall' || newValueLower === '0deleteall') {
            this.setState({
              alertDeleteAll: true
            });
          } else if (hasNewValue) {
            if (type === 'Other' && !this.props.documents.has(newValue)) {
              this.setState({
                alertNotFound: true,
                documentNotFound: newValue
              });
            } else {
              this.setState({
                updateDoc: undefined,
                confirmReplaceDoc: true,
                originalDoc: doc,
                replaceDocNumber: newValue
              });
            }
          } else {
            if (this.isCategoryMatter()) {
              this.setState({
                confirmDeleteFromMatter: doc
              });
            } else {
              this.setState({
                updateDoc: undefined,
                confirmDeleteDocument: doc
              });
            }
          }
          break;
        }
        case 'cited':
          return this.props.updateDocumentCitedState(this.props.matter, documentNumber, newValue)
            .then((showFileModal) => this.setState({ showFileModal }));
        default:
          return this.props.updateDocument({
            id: documentId,
            [field]: newValue
          }, documentNumber, matterId);
      }
    // new document
    } else if (hasNewValue) {
      // add one or multiple documents separated by ;
      if (field === 'documentNumber') {
        if (type === 'US') {
          newValue = getUSDocs(newValue)[0];
        } else if (type === 'Foreign') {
          newValue = doc.setDocumentNumber(newValue).getRealDocumentNumber();
        }
        this.props.addDocumentToMatter({
          matterId,
          documentNumber: newValue,
          type,
          field
        });
      }

      // create npl doc mode
      if (type === 'Other' && field === 'nonPatentBib') {
        this.props.addDocumentToMatter({
          matterId,
          documentNumber: newValue,
          type,
          field
        });
      }
    }
  }

  onShowNPLNumber() {
    this.setState({
      showNPLNumber: !this.state.showNPLNumber
    });
  }

  onDocumentAction(doc, action) {
    switch (action) {
      case 'documentHistory':
        this.setState({
          documentHistory: doc
        });
        break;
      case 'resolveForeign':
        this.props.resolveForeign(doc);
        break;
      case 'deleteFromFamily':
        this.setState({
          confirmDeleteFromFamily: doc
        });
        break;
      case 'deleteFromMatter':
        this.setState({
          confirmDeleteFromMatter: doc
        });
        break;
      case 'deleteFromClient':
        this.setState({
          confirmDeleteFromClient: doc
        });
        break;
      case 'showNPLNumber':
        this.setState({
          showNPLNumber: !this.state.showNPLNumber
        });
        break;
      case 'showPriorityDate':
        this.setState({
          showPriorityDate: !this.state.showPriorityDate
        });
        break;
      case 'deduplicate':
        this.setState({
          deduplicateDocument: doc
        });
        break;
      case 'toggleOtherRef':
        this.props.updateDocument({
          id: doc.get('id'),
          isOtherNpl: !doc.get('isOtherNpl')
        }, doc.get('documentNumber'));
        break;
    }
  }

  closeReplace() {
    this.setState({
      confirmReplaceDoc: false,
      originalDoc: undefined,
      updateDoc: undefined,
      replaceDocNumber: undefined
    });
  }

  cancelReplace() {
    this.setState({
      updateDoc: this.state.originalDoc
    }, this.closeReplace);
  }

  confirmReplace() {
    const doc = this.state.originalDoc;
    const newDoc = doc.setDocumentNumber(this.state.replaceDocNumber);
    const newValue = newDoc.getRealDocumentNumber();
    this.props.replaceDocNumber(doc, newValue);
    this.closeReplace();
  }

  renderConfirmReplace() {
    if (this.state.confirmReplaceDoc) {
      const doc = this.state.originalDoc;
      if (doc) {
        return (
          <ConfirmModal
            message={`Replace document "${doc.get('documentNumber')}" with "${this.state.replaceDocNumber}" in all matters for client ${this.props.client.get('clientNumber')}?`}
            onCancel={this.cancelReplace}
            onConfirm={this.confirmReplace}
            focusCancel
          />
        );
      }
    }
  }

  closeDeleteFromMatter() {
    this.setState({
      updateDoc: this.state.confirmDeleteFromMatter,
      confirmDeleteDocument: undefined,
      confirmDeleteFromMatter: undefined
    }, () => {
      this.setState({
        updateDoc: undefined
      });
    });
  }

  confirmDeleteFromMatter() {
    const matterId = this.props.matter.get('id');
    const doc = this.state.confirmDeleteFromMatter;
    const docNumber = doc.getRealDocumentNumber();
    this.props.deleteDocumentFromMatter(matterId, docNumber);
    this.closeDeleteFromMatter();
  }

  renderDeleteDocumentConfirmFooter() {
    return (
      <div style={{ width: '310px', float: 'left', textAlign: 'left' }}>
        <span className='fa fa-info-circle' style={{ marginRight: '5px' }} />
        To view or return a deleted reference, go to the <strong>Undelete</strong> button in the <strong>Dashboard</strong> header.
      </div>
    );
  }

  renderConfirmDeleteFromMatter() {
    return this.state.confirmDeleteFromMatter && (
      <ConfirmModal
        footer={this.renderDeleteDocumentConfirmFooter()}
        message={`Delete "${this.state.confirmDeleteFromMatter.get('documentNumber')}" from this matter?`}
        onCancel={this.closeDeleteFromMatter}
        onConfirm={this.confirmDeleteFromMatter}
        focusCancel
      />
    );
  }

  closeDeleteFromFamily() {
    this.setState({
      confirmDeleteDocument: undefined,
      confirmDeleteFromFamily: undefined
    });
  }

  confirmDeleteFromFamily() {
    const matterId = this.props.matter.get('id');
    const doc = this.state.confirmDeleteFromFamily;
    const docNumber = doc.getRealDocumentNumber();
    this.props.deleteDocumentFromFamily(matterId, docNumber);
    this.closeDeleteFromFamily();
  }

  renderConfirmDeleteFromFamily() {
    return this.state.confirmDeleteFromFamily && (
      <ConfirmModal
        footer={this.renderDeleteDocumentConfirmFooter()}
        message={`Delete all entries of "${this.state.confirmDeleteFromFamily.get('documentNumber')}" from this family?`}
        onCancel={this.closeDeleteFromFamily}
        onConfirm={this.confirmDeleteFromFamily}
        focusCancel
      />
    );
  }

  closeDeleteFromClient() {
    this.setState({
      confirmDeleteDocument: undefined,
      confirmDeleteFromClient: undefined
    });
  }

  confirmDeleteFromClient() {
    const doc = this.state.confirmDeleteFromClient;
    this.props.deleteDocumentFromClient(
      doc.get('clientNumber'),
      doc.getRealDocumentNumber(),
      doc.get('id')
    );
    this.closeDeleteFromClient();
  }

  renderConfirmDeleteFromClient() {
    if (this.state.confirmDeleteFromClient) {
      return (
        <ConfirmModal
          message={`Delete all entries of "${this.state.confirmDeleteFromClient.get('documentNumber')}" from this client's database?`}
          onCancel={this.closeDeleteFromClient}
          onConfirm={this.confirmDeleteFromClient}
          focusCancel
        />
      );
    }
  }

  renderAlertDeleteAll() {
    return this.state.alertDeleteAll && (
      <AlertModal
        message='DELETEALL is no longer a function. Instead, click "Delete from Client Database" found in the gear icon.'
        onConfirm={() => this.setState({ alertDeleteAll: false })}
        focusConfirm
      />
    );
  }

  renderAlertNotFound() {
    return this.state.alertNotFound && (
      <AlertModal
        message={`Document ${this.state.documentNotFound} does not exists. Please enter an existing document number.`}
        onConfirm={() => this.setState({ alertNotFound: false })}
        focusConfirm
      />
    );
  }

  cancelDeduplicate() {
    this.setState({ deduplicateDocument: false });
  }

  confirmDeduplicate(e) {
    this.props.replaceDocNumber(e.losing, e.winning.getRealDocumentNumber());
    this.setState({ deduplicateDocument: false });
  }

  renderDeduplicateModal() {
    return this.state.deduplicateDocument && (
      <DeduplicateModal
        matter={this.props.matter}
        features={this.props.features}
        documents={this.props.documents}
        showNPLNumber={!!this.state.showNPLNumber}
        onShowNPLNumber={this.onShowNPLNumber}
        doc={this.state.deduplicateDocument}
        firmName={this.props.matter.get('firmName')}
        clientNumber={this.props.matter.get('clientNumber')}
        nplSuggestions={this.props.nplSuggestions}
        searchNplDocuments={this.props.searchNplDocuments}
        clearNplDocuments={this.props.clearNplDocuments}
        fetchDocuments={this.props.fetchDocuments}
        onCancel={this.cancelDeduplicate}
        onConfirm={this.confirmDeduplicate}
      />
    );
  }

  getPendingOfficeActions() {
    if (!this.props.client.get('sweepCitationsFlag') || !this.props.pendingOfficeActions) {
      return [];
    }

    const pendingOfficeActions = this.props.pendingOfficeActions.filter(item => (
      Number(item.matterId) === Number(this.props.matter.get('id'))
    ));

    return sortBy(pendingOfficeActions, [
      (item) => toISODate(item.gd_docDate)
    ]);
  }

  hasPendingOfficeAction() {
    return Boolean(this.getPendingOfficeActions().length);
  }

  onCancelBatchInput() {
    this.setState({
      batchInput: null
    });
  }

  renderBatchInput() {
    return this.state.batchInput && (
      <DocumentBatchModal
        features={this.props.features}
        addBatchDocuments={this.props.addBatchDocuments}
        categories={this.props.categories}
        client={this.props.client}
        matter={this.props.matter}
        onCancel={this.onCancelBatchInput}
        onClose={this.onCancelBatchInput}
        type={this.state.batchInput}
        viewCategory={this.props.viewCategory}
      />
    );
  }

  onBatchInput(type) {
    this.setState({
      batchInput: type
    });
  }

  onCancelBatchSweep() {
    this.setState({
      pendingOfficeAction: null
    });
  }

  renderBatchSweep() {
    return this.state.pendingOfficeAction && (
      <BatchSweepModal
        fetchFamily={this.props.fetchFamily}
        getFirmClientSettings={this.props.getFirmClientSettings}
        fetchPendingOfficeActions={this.props.fetchPendingOfficeActions}
        addBatchDocuments={this.props.addBatchDocuments}
        markOfficeActionsComplete={this.props.markOfficeActionsComplete}
        pendingOfficeActions={this.getPendingOfficeActions()}
        matter={this.props.matter}
        client={this.props.client}
        categories={this.props.categories}
        viewCategory={this.props.viewCategory}
        onCancel={this.onCancelBatchSweep}
        onClose={this.onCancelBatchSweep}
      />
    );
  }

  onCancelDeleteDocument() {
    this.setState({
      updateDoc: this.state.confirmDeleteDocument,
      confirmDeleteDocument: undefined
    });
  }

  renderConfirmDeleteDocument() {
    return this.state.confirmDeleteDocument && (
      <DeleteDocumentModal
        document={this.state.confirmDeleteDocument}
        onCancel={this.onCancelDeleteDocument}
        onAction={this.onDocumentAction}
      />
    );
  }

  onClickCleanUpTwoWay(e) {
    e.stopPropagation();
    this.props.cleanUpTwoWayRelations();
  }

  renderBatchUploadModal() {
    return this.props.batchUploadData && (
      <BatchUploadModal
        features={this.props.features}
        documents={this.props.documents}
        onClose={this.props.closeBatchUpload}
        confirmBatchUpload={this.props.confirmBatchUpload}
        {...this.props.batchUploadData}
      />
    );
  }

  closeFileModal() {
    this.setState({
      showFileModal: false
    });
  }

  renderConfirmFile() {
    return this.state.showFileModal && (
      <ConfirmFileModal
        hideClearFileReminder
        origin='CiteDocument'
        features={this.props.features}
        matter={this.props.matter}
        client={this.props.client}
        logAction={this.props.logAction}
        fileMatter={this.props.fileMatter}
        setFileReminder={this.props.setFileReminder}
        onClose={this.closeFileModal}
        visibleDocuments={this.props.visibleDocuments}
        subsetIDSEnabled={Boolean(this.props.notesSearch)}
      />
    );
  }

  showListed(doc) {
    this.setState({
      showListed: doc
    });
  }

  onCloseListed() {
    this.setState({
      showListed: false
    });
  }

  renderDocumentListed() {
    return this.state.showListed && (
      <DocumentListedModal
        doc={this.state.showListed}
        matter={this.props.matter}
        client={this.props.client}
        navigateApp={this.props.navigateApp}
        onClose={this.onCloseListed}
      />
    );
  }

  closeDeduplicateNPL() {
    this.setState({
      deduplicateNPL: null,
      updateDoc: null
    });
  }

  renderDeduplicateNPL() {
    return this.state.deduplicateNPL && (
      <DeduplicateNPLModal
        doc={this.state.deduplicateNPL.doc}
        matter={this.props.matter}
        onClose={this.closeDeduplicateNPL}
        searchNplDocuments={this.props.searchNplDocuments}
        clearNplDocuments={this.props.clearNplDocuments}
        nplSuggestions={this.props.nplSuggestions}
        description={this.state.deduplicateNPL.newValue}
        replaceDocNumber={this.props.replaceDocNumber}
      />
    );
  }

  closeDocumentHistory() {
    this.setState({
      documentHistory: null
    });
  }

  renderDocumentHistoryModal() {
    return this.state.documentHistory && (
      <DocumentHistoryModal
        fetchDocumentHistory={this.props.fetchDocumentHistory}
        client={this.props.client}
        matter={this.props.matter}
        doc={this.state.documentHistory}
        onClose={this.closeDocumentHistory}
      />
    );
  }

  renderDocuments() {
    return (
      <DocumentsList
        appendTranslationsToDocument={this.props.appendTranslationsToDocument}
        lookUpDocuments={this.props.lookUpDocuments}
        batchUploadDocument={this.props.batchUploadDocument}
        categories={this.props.categories}
        citedFilter={this.props.citedFilter}
        clearNplDocuments={this.props.clearNplDocuments}
        client={this.props.client}
        documents={this.props.documents}
        features={this.props.features}
        fileMatter={this.props.fileMatter}
        loadMatterNotes={this.props.loadMatterNotes}
        logAction={this.props.logAction}
        matter={this.props.matter}
        newDocuments={this.props.newDocuments}
        notesSearch={this.props.notesSearch}
        notesTab={this.props.notesTab}
        nplSuggestions={this.props.nplSuggestions}
        onAction={this.onDocumentAction}
        onBatchInput={this.onBatchInput}
        onChange={this.onDocChange}
        onUpload={this.props.uploadFile}
        refreshDocuments={this.props.refreshDocuments}
        resolveForeign={this.props.resolveForeign}
        searchNplDocuments={this.props.searchNplDocuments}
        selectNotesTab={this.props.selectNotesTab}
        setFileReminder={this.props.setFileReminder}
        showListed={this.showListed}
        showNPLNumber={Boolean(this.state.showNPLNumber)}
        showPriorityDate={Boolean(this.state.showPriorityDate)}
        updateDoc={this.state.updateDoc}
        updateDocument={this.props.updateDocument}
        updateDocumentCategories={this.props.updateDocumentCategories}
        viewCategory={this.props.viewCategory}
        visibleDocuments={this.props.visibleDocuments}
      />
    );
  }

  onClickBatchSweep() {
    this.setState({
      pendingOfficeAction: true
    });
  }

  renderPDFScraperModal() {
    return this.state.showPDFScraper && (
      <BatchPDFScraperModal
        onClose={this.onClosePDFScraper}
        onCancel={this.onClosePDFScraper}
        categories={this.props.categories}
        viewCategory={this.props.viewCategory}
        addBatchDocuments={this.props.addBatchDocuments}
        fetchFamily={this.props.fetchFamily}
        matter={this.props.matter}
        documents={this.state.showPDFScraper}
      />
    );
  }

  onClosePDFScraper() {
    this.setState({
      showPDFScraper: null
    });
  }

  onShowPDFScraper(docs) {
    this.setState({
      showPDFScraper: docs
    });
  }

  renderAdminTools() {
    return this.props.features.admin && this.props.matter && (
      <AdminDropdown
        matter={this.props.matter}
        features={this.props.features}
        uploadPDFScraper={this.props.uploadPDFScraper}
        clearPendingOfficeActions={this.props.clearPendingOfficeActions}
        hasPendingOfficeAction={this.hasPendingOfficeAction()}
        onShowPDFScraper={this.onShowPDFScraper}
        onClickBatchSweep={this.onClickBatchSweep}
        onClickCleanUpTwoWay={this.onClickCleanUpTwoWay}
      />
    );
  }

  renderFilter() {
    return (
      <DocumentsFilter
        citedFilter={this.props.citedFilter}
        selectCitedFilter={this.props.selectCitedFilter}
        searchNotes={this.props.searchNotes}
        notesSearch={this.props.notesSearch}
      />
    );
  }

  renderTools() {
    return (
      <DocumentsTools
        addDocumentToMatter={this.props.addDocumentToMatter}
        clearNearDuplicate={this.props.clearNearDuplicate}
        documents={this.props.documents}
        features={this.props.features}
        fetchDocumentsIfNeeded={this.props.fetchDocumentsIfNeeded}
        fetchNearDuplicate={this.props.fetchNearDuplicate}
        getEquivalents={this.props.getEquivalents}
        loadMatterNotes={this.props.loadMatterNotes}
        logAction={this.props.logAction}
        matter={this.props.matter}
        matterNotes={this.props.matterNotes}
        nearDuplicate={this.props.nearDuplicate}
        onAction={this.onDocumentAction}
        removeEquivalents={this.props.removeEquivalents}
        removeNonPriorArt={this.props.removeNonPriorArt}
        replaceDocNumber={this.props.replaceDocNumber}
        sortDocuments={this.props.sortDocuments}
        updateDocument={this.props.updateDocument}
        updateMatter={this.props.updateMatter}
      />
    );
  }

  renderHeader() {
    if (this.props.matter.isEmpty()) {
      return 'Documents';
    }

    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: '35px',
        right: '5px',
        bottom: 0,
        gap: '7px'
      }}
      >
        Documents
        <div style={{ marginLeft: 'auto' }} />
        {this.renderTools()}
        {this.renderAdminTools()}
      </div>
    );
  }

  renderFull() {
    return (
      <CollapsiblePanel
        id='documents-panel'
        header={this.renderHeader()}
      >
        {this.renderFilter()}
        {this.renderDocuments()}
        {this.renderConfirmReplace()}
        {this.renderConfirmDeleteDocument()}
        {this.renderConfirmDeleteFromClient()}
        {this.renderConfirmDeleteFromMatter()}
        {this.renderConfirmDeleteFromFamily()}
        {this.renderAlertDeleteAll()}
        {this.renderAlertNotFound()}
        {this.renderBatchInput()}
        {this.renderBatchSweep()}
        {this.renderPDFScraperModal()}
        {this.renderBatchUploadModal()}
        {this.renderDeduplicateModal()}
        {this.renderConfirmFile()}
        {this.renderDeduplicateNPL()}
        {this.renderDocumentListed()}
        {this.renderDocumentHistoryModal()}
      </CollapsiblePanel>
    );
  }

  renderEmpty() {
    return (
      <CollapsiblePanel
        id='documents-panel'
        expanded={false}
        header={this.renderHeader()}
      />
    );
  }

  render() {
    return this.props.matter.isEmpty() ? this.renderEmpty() : this.renderFull();
  }
};
