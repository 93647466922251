import TodoRecord from '../models/TodoRecord';
import { getPtoFeeData } from './get-pto-fee-data';
import { getPairStatusData } from './get-pair-status-data';
import Immutable from 'immutable';

export function getTodoRecord(matter, client, extra) {
  const attorneyEmail = matter.get('associateEmail') || client.get('partnerEmail');

  return new TodoRecord({
    alternateId: matter.get('alternateId'),
    applicationNumber: matter.get('applicationNumber'),
    approved: matter.get('approved'),
    artUnit: matter.get('artUnit'),
    attorneyEmail: attorneyEmail ? attorneyEmail.toLowerCase() : null,
    cited: matter.get('citedArt').size,
    citedArtCount: matter.get('citedArtCount'),
    clientId: client.get('id'),
    clientNumber: matter.get('clientNumber'),
    confirmationNumber: matter.get('confirmationNumber'),
    entitySize: matter.get('entitySize') || client.get('entitySize'),
    fileReminder: matter.get('fileReminder'),
    filingDate: matter.get('filingDate'),
    firstInventor: matter.get('firstInventor'),
    id: matter.get('id'),
    matterClosed: matter.get('matterClosed'),
    matterId: matter.get('id'),
    matterNumber: matter.get('matterNumber'),
    pairStatus: matter.get('pairStatus'),
    pairStatusDate: matter.get('pairStatusDate'),
    pairStatusLookupDate: matter.get('pairStatusLookupDate'),
    patentCenterPairStatus: matter.get('patentCenterPairStatus'),
    patentCenterPairStatusDate: matter.get('patentCenterPairStatusDate'),
    patentCenterPairStatusLookupDate: matter.get('patentCenterPairStatusLookupDate'),
    pairStatusData: Immutable.fromJS(getPairStatusData(matter.toJS())),
    ptoFeeData: getPtoFeeData(matter, client),
    statement: matter.get('statement'),
    timing: matter.get('timing'),
    uncited: matter.get('uncitedArt').size,
    urgent: matter.get('urgent'),
    within30Days: matter.get('within30Days'),
    ...extra
  });
}
