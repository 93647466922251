import callApi from '../../utils/callApi';
import addNotification from '../app/addNotification';

export default (matter) => (dispatch) => {
  const matterId = matter.get('id');
  const notificationId = `update-previously-paid-amount-${matterId}`;

  dispatch(addNotification({
    id: notificationId,
    type: 'progress',
    message: 'Checking Patent Center...'
  }));

  return dispatch(callApi({
    url: '/api/migrations/update-previously-paid-amount',
    method: 'POST',
    data: {
      matterId
    }
  })).then((response) => {
    dispatch(addNotification({
      id: notificationId,
      type: 'success',
      message: response || 'Done'
    }));
  }).catch((error) => {
    const message = error.response && error.response.data
      ? error.response.data
      : 'Something wrong...';

    dispatch(addNotification({
      id: notificationId,
      type: 'error',
      message
    }));
  });
};
