import { combineReducers } from 'redux';

import activePage from './activePage';
import currentSearchParams from './currentSearchParams';
import defaultTerm from './defaultTerm';
import filterType from './filterType';
import isLoadingTodos from './isLoadingTodos';
import pageSize from './pageSize';
import show from './show';
import sort from './sort';
import term from './term';

export default combineReducers({
  activePage,
  currentSearchParams,
  defaultTerm,
  filterType,
  isLoadingTodos,
  pageSize,
  show,
  sort,
  term
});
