import jwtDecode from 'jwt-decode';
import checkAuth from './checkAuth';
import Cookies from 'js-cookie';

export default () => (dispatch, getState) => {
  const { auth: { token: tokenFromState } } = getState();
  const tokenFromCookie = Cookies.get('syncids-session');

  if (!tokenFromState || !tokenFromCookie) {
    return Promise.resolve(false);
  }

  const decodedTokenFromState = decodeToken(tokenFromState);
  const decodedTokenFromCookie = decodeToken(tokenFromCookie);

  if (!decodedTokenFromState || !decodedTokenFromCookie) {
    return Promise.resolve(false);
  }

  if (decodedTokenFromState.loginEmail !== decodedTokenFromCookie.loginEmail) {
    window.location.reload();
    return Promise.resolve(false);
  }

  const currentTime = Math.floor(Date.now() / 1000);
  const expiresIn = decodedTokenFromState.exp - currentTime;
  const expiration = 80 * 60; // 1h20min

  if (expiresIn < expiration) {
    return dispatch(checkAuth('/refresh'));
  }

  return Promise.resolve(true);
};

function decodeToken(token) {
  try {
    return jwtDecode(token);
  } catch (e) {
    return null;
  }
}
