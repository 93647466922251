import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import { Modal, Button } from 'react-bootstrap';
import Field from '../shared/Field';
import HelpIcon from '../shared/HelpIcon';
import DocumentDescription from './DocumentDescription';
import DocumentRecord from '../../models/DocumentRecord';

export default class DeduplicateModal extends Component {
  static propTypes = {
    features: PropTypes.object.isRequired,
    matter: PropTypes.instanceOf(Immutable.Map).isRequired,
    documents: PropTypes.instanceOf(Immutable.Map).isRequired,
    fetchDocuments: PropTypes.func.isRequired,
    doc: PropTypes.instanceOf(DocumentRecord).isRequired,
    nplSuggestions: PropTypes.instanceOf(Immutable.Map).isRequired,
    firmName: PropTypes.string.isRequired,
    clientNumber: PropTypes.string.isRequired,
    searchNplDocuments: PropTypes.func.isRequired,
    clearNplDocuments: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onShowNPLNumber: PropTypes.func.isRequired,
    showNPLNumber: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      show: true,
      losing: this.props.doc,
      winning: new DocumentRecord({
        documentNumber: '',
        nonPatentBib: ''
      })
    };
  }

  close() {
    this.setState({ show: false });
    this.props.clearNplDocuments();
  }

  componentDidUpdate() {
    const docsToFetch = [this.state.losing, this.state.winning]
      .filter(Boolean)
      .map((doc) => doc.get('documentNumber'))
      .filter(Boolean)
      .filter(documentNumber => !this.props.documents.get(documentNumber));

    if (docsToFetch.length) {
      this.props.fetchDocuments(this.props.matter, docsToFetch);
    }
  }

  onClickCancel() {
    this.close();
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  isValid() {
    return Boolean(
      this.state.losing.get('id') &&
      this.state.winning.get('id'));
  }

  onClickConfirm() {
    if (this.isValid()) {
      if (this.props.onConfirm) {
        this.props.onConfirm(this.state);
      }
      this.close();
    }
  }

  findDocumentByDescription(value) {
    const data = {
      type: 'Other',
      firmName: this.props.firmName,
      clientNumber: this.props.clientNumber
    };

    const doc = this.props.nplSuggestions.find((doc) => (
      doc.get('nonPatentBib').trim() === value.trim()
    ));

    if (doc) {
      return new DocumentRecord(doc.merge(data));
    } else {
      return new DocumentRecord({
        ...data,
        nonPatentBib: value
      });
    }
  }

  onChangeLosing(doc, field, newValue) {
    this.setState({
      losing: this.findDocumentByDescription(newValue)
    });
  }

  onChangeWinning(doc, field, newValue) {
    const winning = this.findDocumentByDescription(newValue);
    if (winning) {
      this.setState({
        winning
      });
    }
  }

  onSearchDescription(value) {
    const { firmName, clientNumber } = this.props;
    this.props.searchNplDocuments(firmName, clientNumber, value);
  }

  renderPDF(dedupDoc) {
    const doc = this.props.documents.get(dedupDoc.get('documentNumber'));

    if (!doc || doc.get('stored') !== 'Yes') {
      return null;
    }

    return (
      <a
        className='btn btn-xs btn-secondary'
        target='_blank'
        rel='noreferrer'
        href={doc.get('pdfUrl')}
        style={{ marginTop: '-3px', marginLeft: '10px', padding: '0 5px' }}
      >
        <span className='fa text-danger fa-file-pdf-o' />
      </a>
    );
  }

  renderWinningLabel() {
    let label = 'Winning Description';

    if (this.props.showNPLNumber && this.state.winning && this.state.winning.get('documentNumber')) {
      label += ` (${this.state.winning.get('documentNumber')})`;
    }

    return (
      <>
        {label}
        {this.renderPDF(this.state.winning)}
      </>
    );
  }

  renderLosingLabel() {
    let label = 'Losing Description';

    if (this.props.showNPLNumber && this.state.losing && this.state.losing.get('documentNumber')) {
      label += ` (${this.state.losing.get('documentNumber')})`;
    }

    return (
      <>
        {label}
        {this.renderPDF(this.state.losing)}
      </>
    );
  }

  getSuggestions() {
    return this.props.nplSuggestions.delete(this.state.losing.get('documentNumber'));
  }

  renderForm() {
    return (
      <div>
        <Field label={this.renderLosingLabel()}>
          <DocumentDescription
            features={this.props.features}
            doc={this.state.losing}
            nplSuggestions={this.getSuggestions()}
            field='nonPatentBib'
            onChange={this.onChangeLosing}
            onSearch={this.onSearchDescription}
            clearNplDocuments={this.props.clearNplDocuments}
            changeOnSelect
          />
        </Field>
        <Field style={{ marginTop: '10px' }} label={this.renderWinningLabel()}>
          <DocumentDescription
            features={this.props.features}
            doc={this.state.winning}
            nplSuggestions={this.getSuggestions()}
            field='nonPatentBib'
            onChange={this.onChangeWinning}
            onSearch={this.onSearchDescription}
            clearNplDocuments={this.props.clearNplDocuments}
            emptyOnBlur={false}
            searchCurrentValue
            changeOnSelect
          />
        </Field>
      </div>
    );
  }

  onClickSwitch() {
    this.setState({
      losing: this.state.winning,
      winning: this.state.losing
    });
  }

  renderSwitch() {
    return (
      <Button
        variant='secondary'
        onClick={this.onClickSwitch}
      >
        <span style={{ marginRight: '5px' }} className='fa fa-retweet' />
        Switch Documents
      </Button>
    );
  }

  renderShowNumber() {
    return (
      <Button
        variant='secondary'
        onClick={this.props.onShowNPLNumber}
      >
        <span
          style={{ marginRight: '5px' }}
          className={this.props.showNPLNumber ? 'fa fa-check-square-o text-success' : 'fa fa-square-o'}
        />
        Show NPL Number
      </Button>
    );
  }

  renderConfirm() {
    return (
      <Button
        disabled={!this.isValid()}
        variant='primary'
        onClick={this.onClickConfirm}
      >
        Confirm
      </Button>
    );
  }

  renderCancel() {
    return (
      <Button
        variant='secondary'
        onClick={this.onClickCancel}
      >
        Cancel
      </Button>
    );
  }

  renderHelpText() {
    const styleTitle = {
      margin: '20px 0 5px 0'
    };
    return (
      <div>
        <p>An advanced feature of SyncIDS is the ability to DeDup, or remove, non-literal duplicates <em>via the user interface</em>.</p>
        <h4 style={styleTitle} className='text-success'>What are non-literal duplicates?</h4>
        <p>When there are two or more descriptions closely describing the same reference.</p>
        <h4 style={styleTitle} className='text-success'>Example</h4>
        <ul>
          <li>Cooper, David S., M.D., &quot;Antithyroid Drugs,&quot; N Engl J Med 2005; 352; 905-917</li>
          <li>Cooper, David S., M.D., &quot;Antithyroid Drugs,&quot; N Engl J Med 2005</li>
        </ul>
        <p>In this example, the first one is the winning description since more information is provided.</p>
        <h4 style={styleTitle} className='text-danger'>WARNING</h4>
        <p>Never delete non-literal duplicates since it may be the sole description in another matter(s). By choosing a winning description, the reference remains while the description is updated.</p>
        <h4 style={styleTitle} className='text-success'>REMINDERS</h4>
        <ul>
          <li>SyncIDS automatically removes literal duplicates in all sections.</li>
          <li>Any edits made to a reference will automatically flow everywhere else that reference is mentioned.</li>
          <li>Auto-Complete. If there are document descriptions already in the database for the current client that are similar to the one you are entering, document windows will pop up to assist you. You can select one of these descriptions with the mouse or the up and down arrow keys. Up to three document window are displayed at a time. If you do not see the document description you are looking for, use the right arrow button to view additional selections.</li>
        </ul>
      </div>
    );
  }

  renderHelpIcon() {
    return (
      <HelpIcon
        help={this.renderHelpText()}
        id='dedup-modal'
        placement='left'
        styleIcon={{ marginLeft: '10px' }}
        stylePopover={{ maxWidth: '600px' }}
      />
    );
  }

  render() {
    return (
      <Modal
        size='lg'
        show={this.state.show}
        onHide={this.onClickCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Deduplicate Non-Patent Literature
            {this.renderHelpIcon()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderForm()}
        </Modal.Body>
        <Modal.Footer>
          {this.renderSwitch()}
          {this.renderShowNumber()}
          {this.renderCancel()}
          {this.renderConfirm()}
        </Modal.Footer>
      </Modal>
    );
  }
}
