import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import { Overlay, Popover, Button, PopoverBody, PopoverHeader } from 'react-bootstrap';
import Immutable from 'immutable';
import { getPairStatusData } from '../../utils/get-pair-status-data';

export default class PairStatusIcon extends Component {
  static propTypes = {
    matter: PropTypes.instanceOf(Immutable.Map).isRequired,
    updateMatter: PropTypes.func.isRequired,
    updateMatterPairStatus: PropTypes.func.isRequired
  };

  static defaultProps = {
    matter: Immutable.Map()
  };

  static getDerivedStateFromProps(props, state) {
    if (props.matter.get('matterId') !== state.matterId) {
      return {
        matterId: props.matter.get('matterId'),
        failed: false,
        show: false,
        ...getPairStatusData(props.matter.toJS())
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }

  assignRef(iconRef) {
    this.setState({ iconRef });
  }

  preventHide() {
    if (this.hideTimeout) {
      clearTimeout(this.hideTimeout);
      this.hideTimeout = null;
    }
  }

  hidePopover() {
    this.hideTimeout = setTimeout(() => this.setState({ show: false }), 300);
  }

  showPopover() {
    this.preventHide();

    this.setState({ show: true });

    if (!this.state.checking && !this.state.checked) {
      this.updatePairStatus();
    }
  }

  updatePairStatus() {
    this.setState({ checking: true });

    this.props.updateMatterPairStatus(this.getMatterId()).then((matter) => {
      if (matter.patentCenterPairStatusDate || matter.pairStatusDate) {
        this.setState({
          ...getPairStatusData(matter),
          failed: false,
          checking: false,
          checked: true
        });
      } else {
        this.setState({
          failed: true,
          checking: false,
          checked: true
        });
      }
    });
  }

  getMatterId() {
    return this.props.matter.get('matterId');
  }

  getPopoverClass() {
    if (this.state.failed) {
      return 'pair-status-fail';
    }
    if (this.state.status) {
      return 'pair-status-success';
    }
  }

  getPopoverTitle() {
    if (this.state.private) {
      return 'PAIR Status';
    }
    if (this.state.status) {
      return 'Public PAIR Status';
    }
  }

  getStatus() {
    const parts = [];

    if (this.state.status) {
      parts.push(`${this.state.status} - ${this.state.date}`);
    }

    if (this.state.lookupDate) {
      parts.push(`Last checked: ${this.state.lookupDate}`);
    }

    if (this.state.checking) {
      parts.push('Checking...');
    } else if (this.state.failed) {
      parts.push('PAIR status not available');
    }

    return parts.map((part, index) => <div key={index}>{part}</div>);
  }

  onClickClose() {
    this.props.updateMatter({
      id: this.props.matter.get('matterId'),
      matterClosed: 'ON'
    }, this.props.matter.get('matterNumber'));
  }

  shouldShowCloseButton() {
    return (
      !this.state.failed &&
      this.state.status &&
      /patented|abandoned/i.test(this.state.status) &&
      this.props.matter.get('matterClosed') !== 'ON'
    );
  }

  renderCloseButton() {
    return (
      <>
        <hr />
        <Button key='close' variant='primary' size='sm' onClick={this.onClickClose}>
          Close Matter
        </Button>
      </>
    );
  }

  renderOverlay() {
    return (
      <Overlay
        placement='right'
        target={this.state.iconRef}
        show={this.state.show}
      >
        <Popover
          onMouseOver={this.preventHide}
          onMouseOut={this.hidePopover}
          className={this.getPopoverClass()}
          id={`pair-status-${this.getMatterId()}`}
        >
          <PopoverHeader>
            {this.getPopoverTitle()}
          </PopoverHeader>
          <PopoverBody>
            {this.getStatus()}
            {this.shouldShowCloseButton() && this.renderCloseButton()}
          </PopoverBody>
        </Popover>
      </Overlay>
    );
  }

  renderIcon() {
    return (
      <span
        ref={this.assignRef}
        onMouseOut={this.hidePopover}
        onMouseOver={this.showPopover}
        className='fa fa-info-circle'
      />
    );
  }

  render() {
    if (!this.props.matter.get('applicationNumber')) {
      return null;
    }

    return (
      <>
        {this.renderIcon()}
        {this.renderOverlay()}
      </>
    );
  }
};
