import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import MatterRelationInput from '../matter/MatterRelationInput';
import Field from '../shared/Field';
import SyncFamily from './SyncFamily';
import HelpIcon from '../shared/HelpIcon';

export default class SyncParentPrune extends Component {
  static propTypes = {
    client: PropTypes.instanceOf(Immutable.Map).isRequired,
    matter: PropTypes.instanceOf(Immutable.Map).isRequired,
    matterList: PropTypes.instanceOf(Immutable.List).isRequired,
    addParentMatter: PropTypes.func.isRequired,
    deleteParentMatter: PropTypes.func.isRequired,
    fetchParentMatters: PropTypes.func.isRequired,
    createMatter: PropTypes.func.isRequired,
    addNotification: PropTypes.func.isRequired,
    selectMatter: PropTypes.func.isRequired,
    features: PropTypes.object.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    if (props.matter.get('id') !== state.matterId) {
      return {
        matterId: props.matter.get('id'),
        parentMatters: null
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {};
  }

  componentDidMount() {
    this.fetchParentMatters();
  }

  componentDidUpdate() {
    this.fetchParentMatters();
  }

  fetchParentMatters(force) {
    if (force || !this.state.parentMatters) {
      this.props.fetchParentMatters(this.props.matter.get('id')).then(response => {
        this.setState({
          parentMatters: response
        });
      });
    }
  }

  getCount() {
    return this.state.parentMatters ? this.state.parentMatters.length : 0;
  }

  onAdd(matterNumbers, resetValue) {
    let promise = Promise.resolve();

    matterNumbers.forEach((matterNumber) => {
      const matter = this.props.matterList.find(m => m.get('matterNumber') === matterNumber);
      if (matter) {
        promise = promise.then(() => this.props.addParentMatter(this.props.matter.get('id'), matter.get('id')));
      }
    });

    promise
      .then(resetValue)
      .then(() => this.fetchParentMatters(true));
  }

  onRemove(matterNumber) {
    const matter = this.props.matterList.find(m => m.get('matterNumber') === matterNumber);
    if (matter) {
      this.props.deleteParentMatter(this.props.matter.get('id'), matter.get('id'))
        .then(() => this.fetchParentMatters(true));
    }
  }

  renderHelp() {
    return (
      <div>
        Parent Prune ({this.getCount()})
        &nbsp;&nbsp;&nbsp;
        <HelpIcon
          id='sync-panel'
          help={(
            <>
              <strong>Parent Prune</strong> is an on-demand tool that deletes uncited references in a continuing application that are cited in a parent case according to SyncIDS.  Users should ensure that all cited references in the parent case were considered by the Examiner before use.  This tool streamlines the IDS review process by minimizing the number of references patent practitioners and Examiners need to consider. Deleted references can be viewed by clicking the <strong>Deleted List</strong> button in the Documents section. See <a href='https://www.uspto.gov/web/offices/pac/mpep/s609.html#:~:text=609.02%20Information%20Disclosure%20Statements%20in%20Continued%20Examinations%20or%20Continuing%20Applications%20%5BR%2D07.2015%5D' target='_blank' rel='noreferrer'>MPEP 609.02</a> for details before use.
            </>
          )}
        />
      </div>
    );
  }

  getMatterRecord() {
    if (!this.state.parentMatters) {
      return this.props.matter.set('parentMatters', '');
    }

    return this.props.matter.set('parentMatters', this.state.parentMatters.map(m => m.matterNumber).join(', '));
  }

  getRemovableMatterNumbers() {
    if (!this.state.parentMatters) {
      return [];
    }

    return this.state.parentMatters
      .filter(parentMatter => parentMatter.childMatterId === this.props.matter.get('id'))
      .map(parentMatter => parentMatter.matterNumber);
  }

  render() {
    return (
      <Field label={this.renderHelp()}>
        <SyncFamily
          showEmpty
          showRemove
          removableMatterNumbers={this.getRemovableMatterNumbers()}
          field='parentMatters'
          client={this.props.client}
          matter={this.getMatterRecord()}
          matterList={this.props.matterList}
          selectMatter={this.props.selectMatter}
          onRemove={this.onRemove}
        />
        <MatterRelationInput
          confirmOnBlur
          confirmAdd
          preventSpaces
          features={this.props.features}
          confirmMessage={'Are you sure you want to add "$matterNumber$" as a parent matter?'}
          placeholder='Enter a New/Existing Matter ID'
          onAdd={this.onAdd}
          createMatter={this.props.createMatter}
          addNotification={this.props.addNotification}
          matterNumber={this.props.matter.get('matterNumber') || ''}
          matterList={this.props.matterList}
        />
      </Field>
    );
  }
}
