import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import { DropdownButton, Button, Dropdown } from 'react-bootstrap';
import TodoRecord from '../../models/TodoRecord';
import Help from '../shared/Help';
import Tooltip from '../shared/Tooltip';
import IDSModal from './IDSModal';
import { isCategoryMatter } from '../../utils/categories';

const iconWarning = (
  <span className='fa fa-exclamation-triangle text-warning' />
);

const iconChecked = (
  <span className='fa fa-check text-success' />
);

const iconChecking = (
  <span className='fa fa-spinner fa-spin' />
);

const styleHelp = {
  position: 'relative',
  top: '5px'
};

export default class TodoIDS extends Component {
  static propTypes = {
    visibleDocuments: PropTypes.instanceOf(Immutable.List).isRequired,
    todo: PropTypes.instanceOf(TodoRecord).isRequired,
    checkConsistency: PropTypes.func.isRequired,
    clearConsistency: PropTypes.func.isRequired,
    selectedMatterId: PropTypes.string,
    onUpload: PropTypes.func.isRequired,
    showUpload: PropTypes.bool.isRequired,
    authToken: PropTypes.string.isRequired,
    setFileReminder: PropTypes.func.isRequired,
    subsetIDSEnabled: PropTypes.bool,
    features: PropTypes.object.isRequired,
    updateMatterFromPeds: PropTypes.func.isRequired,
    callApi: PropTypes.func.isRequired,
    fileMatter: PropTypes.func.isRequired
  };

  static defaultProps = {
    todo: new TodoRecord(),
    showUpload: false
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      dropdownOpen: false,
      showIDSModal: false
    };
  }

  isSubsetEnabled() {
    return this.props.todo.matterId === this.props.selectedMatterId && this.props.subsetIDSEnabled;
  }

  getIncompleteDocs() {
    if (this.isSubsetEnabled()) {
      return this.props.visibleDocuments.filter(doc => !doc.canDocBeFiled()).size;
    } else {
      return this.props.todo.getIncompleteDocsCount();
    }
  }

  renderDocsWarning() {
    const counter = this.getIncompleteDocs();
    const icon = counter > 0 ? iconWarning : counter === 0 ? iconChecked : iconChecking;
    const message = counter > 0
      ? `${counter} uncited references missing required fields.`
      : counter === 0 ? 'All uncited references are valid.' : 'Validating uncited references...';
    return (
      <Dropdown.Item>
        {icon}
        <span style={{ marginLeft: '10px' }}>{message}</span>
        {counter > 0 && (
          <Help style={styleHelp} text='Required: PDF, Date, Inventor or CC' />
        )}
      </Dropdown.Item>
    );
  }

  onToggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    }, () => {
      if (this.state.dropdownOpen) {
        this.props.updateMatterFromPeds(this.props.todo.id)
          .then(() => this.props.checkConsistency(this.props.todo.id));
        ;
      } else {
        this.props.clearConsistency(this.props.todo.id);
      }
    });
  }

  onClickDropdown(e) {
    e.stopPropagation();
  }

  onClickDownload() {
    this.setState({
      showIDSModal: true,
      dropdownOpen: false
    });
  }

  onConfirmHide() {
    this.setState({
      showIDSModal: false,
      dropdownOpen: false
    });
  }

  renderIDSModal() {
    return this.state.showIDSModal && (
      <IDSModal
        fileMatter={this.props.fileMatter}
        callApi={this.props.callApi}
        showUpload={this.props.showUpload}
        onUpload={this.props.onUpload}
        features={this.props.features}
        visibleDocuments={this.props.visibleDocuments}
        subsetIDSEnabled={this.isSubsetEnabled()}
        todo={this.props.todo}
        authToken={this.props.authToken}
        setFileReminder={this.props.setFileReminder}
        onClose={this.onConfirmHide}
      />
    );
  }

  renderDisabled() {
    return (
      <Tooltip
        id={`ids-download-${this.props.todo.id}`}
        tip='There are currently no uncited references.'
        placement='right'
      >
        <Button size='sm' style={{ cursor: 'not-allowed' }}>
          <span className='fa fa-folder-o text-mute' />
        </Button>
      </Tooltip>
    );
  }

  isVisible() {
    return Boolean(this.props.todo.uncited > 0 && !this.props.todo.isClosed());
  }

  hasReminder() {
    return Boolean(this.props.todo.fileReminder && !isCategoryMatter(this.props.todo.matterNumber));
  }

  renderRedArrow() {
    return (
      <span
        style={{ fontSize: '10px', position: 'absolute', bottom: '0px', left: '-4px' }}
        className='fa fa-arrow-left text-info'
      />
    );
  }

  renderGreenArrow() {
    return (
      <span
        style={{ fontSize: '10px', position: 'absolute', bottom: '-2px', right: '1px' }}
        className='fa fa-arrow-down text-success'
      />
    );
  }

  renderFolder() {
    return (
      <span className='fa fa-folder-o' />
    );
  }

  renderIcon() {
    if (this.isVisible()) {
      return (
        <div style={{ position: 'relative' }}>
          {this.renderFolder()}
          {this.hasReminder() ? this.renderRedArrow() : this.renderGreenArrow()}
        </div>
      );
    }

    return this.renderFolder();
  }

  renderDownload() {
    return (
      <Dropdown.Item
        onClick={this.onClickDownload}
        style={{ marginBottom: '5px', cursor: 'pointer' }}
      >
        <span className='fa fa-download' style={{ marginRight: '5px' }} />
            Download IDS Package
      </Dropdown.Item>
    );
  }

  render() {
    if (!this.props.todo.uncited) {
      return this.renderDisabled();
    }

    return (
      <div>
        <DropdownButton
          size='sm'
          variant='warning'
          className='no-caret'
          title={this.renderIcon()}
          onClick={this.onClickDropdown}
          onToggle={this.onToggle}
          show={this.state.dropdownOpen}
          id={`ids-download-${this.props.todo.id}`}
        >
          {this.renderDocsWarning()}
          <Dropdown.Divider />
          {this.renderDownload()}
        </DropdownButton>
        {this.renderIDSModal()}
      </div>
    );
  }
};
