import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import CollapsiblePanel from '../shared/CollapsiblePanel';
import { isCategoryMatter } from '../../utils/categories';
import HelpIcon from '../shared/HelpIcon';
import PtoFeeStatement from './PtoFeeStatement';
import PtoFeeTiming from './PtoFeeTiming';
import PtoFeeField from './PtoFeeField';
import PtoFeeInput from './PtoFeeInput';
import PtoFeeDropdown from './PtoFeeDropdown';
import PairStatusIcon from './PairStatusIcon';
import { Table } from 'react-bootstrap';
import { getPtoFeeData } from '../../utils/get-pto-fee-data';
import ptoSizeFeeImage from '../../files/pto-size-fee.jpg';

export default class PtoFee extends Component {
  static propTypes = {
    matter: PropTypes.instanceOf(Immutable.Map).isRequired,
    client: PropTypes.instanceOf(Immutable.Map).isRequired,
    documents: PropTypes.instanceOf(Immutable.Map).isRequired,
    expanded: PropTypes.bool.isRequired,
    togglePtoFeePanel: PropTypes.func.isRequired,
    fetchDocumentsIfNeeded: PropTypes.func.isRequired,
    updateMatter: PropTypes.func.isRequired,
    updateMatterPairStatus: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  getRecord() {
    return this.props.matter.set('ptoFeeData', getPtoFeeData(this.props.matter, this.props.client));
  }

  isCategoryMatter() {
    return isCategoryMatter(this.props.matter.get('matterNumber'));
  }

  renderCitedHelp() {
    return (
      <>
      This number <strong>does not</strong> automatically include references cited on IDSs <strong>outside of SyncIDS</strong>. Users can adjust the number as needed. It <strong>will</strong> automatically increase as IDSs are correctly marked as filed using the <strong>CITED</strong> button in the Dashboard section.
      </>
    );
  }

  renderFeeHelp() {
    return (
      <>
        <p>The <strong>IDS Size PTO Fee</strong> is calculated based on the sum of <strong>Previously Cited References on IDSs Only</strong> and <strong>Uncited References</strong>. For <strong>Previously Cited References on IDSs Only</strong> submitted before January 19, 2025, these are included in the <strong>Total Cumulative</strong> count. However, a fee is incurred only if the cumulative total exceeds the next applicable threshold.</p>
        <p>USPTO thresholds as of 1/19/2025:</p>
        <img src={ptoSizeFeeImage} style={{ width: '100%' }} />
      </>
    );
  }

  renderContent() {
    const matter = this.getRecord();

    const timing = {
      header: 'Timing',
      style: { width: '6rem' },
      value: (
        <PtoFeeTiming
          matter={matter}
          updateMatter={this.props.updateMatter}
        />
      )
    };

    const statement = {
      header: 'Statement',
      style: { width: '7rem' },
      value: (
        <PtoFeeStatement
          matter={matter}
          documents={this.props.documents}
          updateMatter={this.props.updateMatter}
          fetchDocumentsIfNeeded={this.props.fetchDocumentsIfNeeded}
        />
      )
    };

    const pairStatus = {
      header: 'PAIR',
      style: { width: '4em', textAlign: 'center' },
      value: (
        <PairStatusIcon
          matter={matter}
          updateMatter={this.props.updateMatter}
          updateMatterPairStatus={this.props.updateMatterPairStatus}
        />
      )
    };

    const timingPtoFee = {
      header: (
        <div className='text-danger'>
          TIMING Fee Due
        </div>
      ),
      value: (
        <PtoFeeField
          value={matter.getIn(['ptoFeeData', 'ptoTimingFee', 'label'])}
        />
      )
    };

    const previousCitedCount = {
      header: (
        <>
          Previously Cited Refs on IDSs Only
          &nbsp;
          <HelpIcon
            id='pto-fee-cited-art-tooltip'
            help={this.renderCitedHelp()}
          />
        </>
      ),
      value: (
        <PtoFeeInput
          matter={matter}
          field='citedArtCount'
          updateMatter={this.props.updateMatter}
        />
      )
    };

    const uncitedCount = {
      header: 'Uncited References',
      value: (
        <PtoFeeField
          value={matter.getIn(['ptoFeeData', 'uncitedCount'])}
        />
      )
    };

    const totalCumulativeAmount = {
      header: 'Total Cumulative',
      value: (
        <PtoFeeField
          value={matter.getIn(['ptoFeeData', 'totalCumulativeAmount', 'label'])}
        />
      )
    };

    const previousPaidAmount = {
      header: 'Previously Paid',
      value: (
        <PtoFeeDropdown
          matter={matter}
          updateMatter={this.props.updateMatter}
          field='previouslyPaidAmount'
          options={[
            { label: '$0', value: 0 },
            { label: '$200', value: 200 },
            { label: '$500', value: 500 },
            { label: '$800', value: 800 }
          ]}
        />
      )
    };

    const sizePtoFee = {
      header: (
        <div className='text-danger'>
          IDS SIZE Fee Due
          &nbsp;
          <HelpIcon
            id='pto-fee-size-tooltip'
            placement='left-end'
            help={this.renderFeeHelp()}
          />

        </div>
      ),
      value: (
        <PtoFeeField
          value={matter.getIn(['ptoFeeData', 'ptoSizeFee', 'label'])}
        />
      )
    };

    const columns = [
      timing,
      statement,
      pairStatus,
      timingPtoFee,
      previousCitedCount,
      uncitedCount,
      totalCumulativeAmount,
      previousPaidAmount,
      sizePtoFee
    ];

    return (
      <Table
        id='pto-fee-list'
        style={{ tableLayout: 'fixed' }}
        hover
        borderless
      >
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index} style={column.style}>{column.header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {columns.map((column, index) => (
              <td key={index} style={column.style}>{column.value}</td>
            ))}
          </tr>
        </tbody>
      </Table>
    );
  }

  renderHelp() {
    return (
      <div>PTO Fee Navigator assists users in determining any PTO fees based on Timing/Statement choices and IDS Size PTO Fee requirements.</div>
    );
  }

  renderHeader() {
    return (
      <div>
        PTO Fee Navigator
        &nbsp;&nbsp;&nbsp;
        <HelpIcon
          id='pto-fee-panel-tooltip'
          help={this.renderHelp()}
        />
      </div>
    );
  }

  renderFull() {
    return (
      <CollapsiblePanel
        id='pto-fee-panel'
        disabled={this.isCategoryMatter()}
        expanded={this.props.expanded && !this.isCategoryMatter()}
        header={this.renderHeader()}
        onToggle={this.props.togglePtoFeePanel}
      >
        {this.renderContent()}
      </CollapsiblePanel>
    );
  }

  renderEmpty() {
    return (
      <CollapsiblePanel
        id='pto-fee-panel'
        expanded={false}
        header={this.renderHeader()}
      />
    );
  }

  render() {
    return this.props.matter.isEmpty() ? this.renderEmpty() : this.renderFull();
  }
}
